.notes-input-header{
    overflow: hidden;
    margin-bottom: 15px;
}

.note-editor-remove-btn{
    margin-top: 5px;
    width: 100%;;
}

.note-editor{
    padding: 0;
}