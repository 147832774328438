:root {
  font-size: 16px;
}

p, h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  font-weight: 400;
  margin-bottom: 0;
  color: $color-text;
}

p {
  margin: 10px 0 0 0;

  &:first-child {
    margin: 0;
  }

  &.bold {
    font-weight: 700;
  }

  &.highlight {
    background-color: $color-primary;
    color: #ffffff !important;
  }

  &.sub {
    opacity: 0.7;
  }
}

h1 {
  font-size: 2.25rem;
  line-height: 3rem;

  &.subhead {
    font-size: 1.875rem;
    line-height: 2.25rem;
    color: $color-primary;
  }
}

h2 {
  font-size: 1.875rem;
  line-height: 2.25rem;

  &.subhead {
    font-size: 1.5rem;
    line-height: 2rem;
    color: $color-primary;
  }
}

h3 {
  font-size: 1.5rem;
  line-height: 2rem;

  &.subhead {
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: $color-primary;
  }
}

h4 {
  font-size: 1.125rem;
  line-height: 1.5rem;

  &.subhead {
    font-size: 0.75rem;
    line-height: 1rem;
    color: $color-primary;
  }
}

blockquote {
  font-size: 0.85rem;
  font-style: italic;
  border-left: 3px solid $color-accent;
  margin-bottom: 0;
  padding-left: 10px;
}

::selection {
  color: #ffffff;
  background: $color-accent;
}

.sidebar, .topbar, .MuiBox-root {
  svg[data-prefix="fad"], svg[data-prefix="far"], svg[data-prefix="fas"], svg[data-prefix="fal"] {
    color: $color-icon;
  }
}

.page-title {
  font-weight: 500;
  text-transform: capitalize;
  font-size: 1.25rem;

  &:last-child {
    margin-bottom: 10px;
  }

  &.page-title--not-last {
    margin-top: 20px;
  }
}

.page-subhead {
  margin-bottom: 20px;

  &.subhead {
    font-size: 0.875rem;
    opacity: 0.7;
  }
}