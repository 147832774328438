.color-danger {
  color: $color-red;
}

.color-keypoint {
    color: $color-primary;
}

[color="keypoint"] {
  color: $color-primary;
}

[color="danger"] {
  color: $color-red;
}

.bg-primary {
  background-color: $color-primary !important;
}

.bg-secondary {
  background-color: $color-secondary !important;
}