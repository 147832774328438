.topbar {
  position: fixed;
  display: flex;
  width: 100%;
  top: 0;
  min-height: 60px;
  z-index: 101;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  background: $color-background;
}

.topbar__left {
  position: absolute;
  display: flex;
  height: 100%;
  width: 50%;
  left: 0px;
}

.topbar__right {
  position: absolute;
  display: flex;
  height: 100%;
  right: 0px;
  margin-right: 15px;
}

@media screen and (max-width: 576px) {

  .topbar {
    min-height: 120px;
  }

  .topbar__right {
    height: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-end;
  }

  .topbar__profile, .topbar__team {
    margin: inherit;
  }
}

@media screen and (max-width: 576px) {

  .topbar {
    min-height: 120px;
  }

  .topbar__right {
    height: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-end;
  }
}

@media screen and (max-width: 320px) {

  .topbar__right {
    justify-content: flex-end;
  }
}

.topbar__button {
  align-items: center;
  display: flex;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: 0.3s;

  &:focus {
    outline: none;
  }

  &:hover {
    background: $color-hover;
  }
}

.topbar__button-icon {
  transition: all 0.3s;
  width: 16px;
  z-index: 101;
  margin-right: 10px;
}