.datatable-filter {
  z-index: 101;
  width: 100%;
  min-width: 250px;

  border-radius: 10px;
  border: 1px solid;
  padding: 15px;
  margin-top: 15px;
  border-color: $color-secondary;
  background: $color-background;

  h4.title {
    color: $color-secondary;
  }
}