html {
  box-sizing: border-box;
}

.container__wrap {
  padding-top: 45px !important;
}

*, *:before, *:after {
  box-sizing: inherit;
}
