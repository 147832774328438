.building-not-found {
    text-align: center;
    color: $color-text;
  }

.error-title {
    font-size: 10em;
  }
  
  .error-subtitle {
    font-size: 2em;
  }