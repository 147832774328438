$color-primary: #0047a0;
$color-secondary:  #f50057;
$color-background: white;
$color-header-data-picker:#6db0ff;
$color-text: #646777;
$color-text-additional: #646777;
$logo-img: url(../../shared/img/logo/logo_light.png);
$color-hover: #fafbfe;
$color-folder-hover: #f0eeee;
$color-border: #eff1f5;
$color-icon: #646777;
$img-invert: invert(0%);
$color-fields-border: #f2f4f7;
$color-scrollbar: #B4BFD0;
$color-fitness: #646777;
$color-emoji: #232329;
$color-accent:  #f50057;
$input-background: #ffffff;

$color-danger: rgb(244, 67, 54);
$color-success: rgb(102, 187, 106);

$color-accent: #f50057;
$color-light-accent: #55e5bb;
$color-accent-hover: darken($color-accent, 10%);
$color-additional: #0047a0;
$color-additional-hover: darken($color-additional, 10%);

$color-yellow: #f6da6e;
$color-light-yellow: #f4dd87;
$color-yellow-hover: darken($color-yellow, 10%);

$color-violet: #c88ffa;

$color-red: #ff4861;
$color-dark-red: #721c24;
$color-light-red: #f88e7d;
$color-very-light-red: #f8d7da;
$color-red-hover: darken($color-red, 10%);

$color-blue: #70bbfd;
$color-light-blue: #87c3f7;
$color-blue-hover: darken($color-blue, 10%);

$color-black: #000000;
$color-gray: #787985;
$color-light-gray: #d8dfe9;
$color-dusty-white: #dddddd;
$color-white: #ffffff;
$white: #ffffff;

$color-green: #b8e986;
$color-green-hover: darken($color-green, 10%);

$color-dark-fields-border: #33333a;
$color-black-background: #232329;
$color-background-body: #f2f4f7;
$color-text: #646777;
$color-hover: #fafbfe;


$borders: (
  round-borders-on: (
    borderRadius: 15px,
  ),
  round-borders-off: (
    borderRadius: 5px,
  )
);

@mixin borderify($borders) {
  @each $border, $map in $borders {
    .#{$border} & {
      $border-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($borders, $border), '#{$key}');
        $border-map: map-merge($border-map, ($key: $value)) !global;
      }
      @content;
      $border-map: null !global;
    }
  }
}

@function bordered($key) {
  @return map-get($border-map, $key);
}

$shadows: (
  blocks-shadows-on: (
    blocksShadows: 0 10px 30px 1px rgba(0, 0, 0, 0.06),
  ),
  blocks-shadows-off: (
    blocksShadows: none,
  )
);

@mixin shadowify($borders) {
  @each $shadow, $map in $shadows {
    .#{$shadow} & {
      $shadow-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($shadows, $shadow), '#{$key}');
        $shadow-map: map-merge($shadow-map, ($key: $value)) !global;
      }
      @content;
      $shadow-map: null !global;
    }
  }
}

@function shadowed($key) {
  @return map-get($shadow-map, $key);
}
