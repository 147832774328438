.dropzone{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin-bottom: 20px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
}

.dropzone-files{
    align-items: flex-start;
}

.dropzone-rejected-files{
    margin-top: 15px;
    align-items: flex-start;
}

.dropzone-rejected-file{
    background-color: $color-danger !important;
    color: white !important;
}