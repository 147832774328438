.pagination {
  display: flex;
  align-items: baseline;
}

.pagination__link {
  background: transparent;
  border: none;
  color: $color-additional;
  height: 28px;
  font-size: 10px;
  padding: 5px;
  text-align: center;
  min-width: 28px;
  transition: all 0.3s;

  margin-right: 2px;

  &:hover {
    color: $color-accent;
    background: transparent;
  }

  &:focus, &:active {
    box-shadow: none;
  }

  svg {
    transition: 0.3s;
  }

  &.pagination__link--arrow {
    border-radius: 50% !important;
    width: 28px;
    height: 28px;

    margin-top: auto;
    margin-bottom: auto;
    background: $color-fields-border;

    &:hover {
      background: $color-accent;

      svg {
        fill: white;
      }
    }

    &:disabled {
      cursor: default;
      opacity: 0.4;
      background: $color-fields-border;

      svg {
        fill: $color-text;
      }
    }
  }
}

.pagination__item {

  &.active .pagination__link {
    background-color: transparent;
    font-weight: bold;
  color: $color-text;
  }

  &.disabled {

    .pagination__link svg {
      fill: $color-icon;
    }
  }
}

.pagination__link-icon {
  width: 13px;
  height: 13px;
  fill: $color-primary;
}

.pagination__wrap {
  display: flex;
  margin-top: 15px;

  &.pagination__wrap--small {
    margin-top: 0;
    display: block;
  }
}

.page-item {
  margin-top: auto;
  margin-bottom: auto;
}

.pagination-info, .pagination__select-form {
  color: $color-primary;

  font-size: 12px;
  
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 0px;
  margin-left: 10px;

  select {
  background-color: $color-background;
  }
}

.pagination__item-option {
  color: $color-primary;
  margin-bottom: 20px;
  font-size: 14px;

  margin-right: 0px;
  margin-left: 10px;
}