.building-motd {
  margin-bottom: 0.5rem;
  svg {
    fill: inherit;
  }

  p {
    color: inherit;
  }
}

.building-motd-display {
  padding: 0;
}
