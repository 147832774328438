
.escalation-phone-numbers {
	overflow-x:hidden;
	white-space:nowrap;
  display: inline-block;
  vertical-align: middle;
}

.escalation-phone-number-list > li {
	display:inline;
}