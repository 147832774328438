.container {

  @media screen and (min-width: 768px) {
    width: 100%;
    max-width: 1630px;
  }
}

.container__wrap {
  padding-left: 0;
  padding-top: 90px;
  min-height: 100vh;
  transition: padding-left 0.3s;
  background: $color-background-body;
}

.layout {
  &.layout--top-navigation{

    & + .container__wrap{

      @media screen and (min-width: 576px) {
        padding-left: 0;
      }
    }
  }
}

.color-secondary {
  color: $color-primary;
}

hr {
  border-top: 1px solid transparentize($color-fitness, 0.8);
}